<template>
    <div>
        <table border="1" style="width:100%;border-bottom: none;" v-for="(items, indexx) in data" :key="indexx" align="center" cellpadding="10"  cellspacing="0">
          <tr id="dateid">
              <td :colspan="!isdisabled?'12':'17'">
                <template>
                  <el-time-picker
                  :disabled = 'isdisabled'
                  :editable="false"
                    v-model="items.Date1"
                    :placeholder="$t('All.开始时间点')">
                  </el-time-picker>
                  {{$t('All.至')}}
                  <el-time-picker
                  :disabled = 'isdisabled'
                  :editable="false"
                    v-model="items.Date2"
                    :placeholder="$t('All.结束时间点')">
                  </el-time-picker>
                </template>
                <span>{{ items.Ispiece == 1 ? $t('All.首件记录'):'' }}</span>
              </td>
              <td v-show="!isdisabled" colspan="2" class="tdd">{{$t('All.首件')}}</td>
              <td v-show="!isdisabled" colspan="1" class="tdd">
                <el-select style="width: 50px;" value-key="Id" v-model="items.Ispiece" :placeholder="$t('All.选择')">
                    <el-option :label="$t('All.是')" :value='parseInt(1)'></el-option>
                    <el-option :label="$t('All.否')" :value='parseInt(0)'></el-option>
                </el-select>
              </td>
              <td v-show="isdisabled" colspan="1">
                <el-button style="margin-left: 11px;" size="mini" v-show="items.Isbool == 1" type="primary" @click="items.Isbool = 0">{{$t('All.收起')}}</el-button>
                <el-button style="margin-left: 11px;" size="mini" v-show="items.Isbool == 0" type="primary" @click="items.Isbool = 1">{{$t('All.展开')}}</el-button>
              </td>
              <td v-show="!isdisabled" colspan="4">
                <el-button style="margin-left: 11px;" size="mini" v-show="items.Isbool == 1" type="primary" @click="items.Isbool = 0">{{$t('All.收起')}}</el-button>
                <el-button style="margin-left: 11px;" size="mini" v-show="items.Isbool == 0" type="primary" @click="items.Isbool = 1">{{$t('All.展开')}}</el-button>
                <el-button style="margin-left: 11px;" size="mini" type="primary" icon="el-icon-plus" @click="addDomains(items, indexx)"></el-button>
                <el-button v-show="form.Patrol_records.length > 1" size="mini" type="danger" icon="el-icon-delete" @click="removeDomain(items)"></el-button>
              </td>
            </tr>
          <tr v-show="items.Isbool == 1">
              <td rowspan="2" colspan="1" class="tdd">{{$t('All.工序名巡检项目')}}</td>
              <td rowspan="2" colspan="2" class="tdd" >{{$t('All.规格值')}}({{$t('All.要求')}})</td>
              <td rowspan="2" colspan="1" style="width: 60px;" class="tdd">{{$t('All.单位')}}</td>
              <td colspan="10" class="tdd" style="letter-spacing: 56px;margin-left: 56px;text-align: center;">{{$t('All.实际值')}}</td>
              <td rowspan="2" class="tdd">{{$t('All.检验结果')}}</td>
              <td rowspan="2" colspan="3" style="width: 170px;" class="tdd">{{$t('All.是否按SOP要求作业')}}</td>
          </tr>
          <tr v-show="items.Isbool == 1">
              <td colspan="2" class="tdd">1</td>
              <td colspan="2" class="tdd">2</td>
              <td colspan="2" class="tdd">3</td>
              <td colspan="2" class="tdd">4</td>
              <td colspan="2" class="tdd">5</td>
          </tr>
          <tr v-show="items.Isbool == 1" v-for="(item, index) in items.Patrol " :key="index">
            <td colspan="1" class="tdd"><el-input :disabled = 'isdisabled' v-model="item.Patrol_name" ></el-input></td>
            <td colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="item.Specification" ></el-input></td>
            <td v-show="isdisabled"  colspan="1" class="tdd"><el-input :disabled = 'isdisabled' v-model="item.Company" ></el-input></td>
            <td v-show="!isdisabled" colspan="1" style="width: 50px;" class="tdd">
              <el-select style="width: 50px;" allow-create filterable v-model="item.Company" :placeholder="$t('All.选择')">
                  <el-option label="MM" value="MM"></el-option>
                  <el-option label="CM" value="CM"></el-option>
                  <el-option label="KG" value="kG"></el-option>
                  <el-option label="G" value="G"></el-option>
                  <el-option label="PC" value="PC"></el-option>
                  <el-option label="°C" value="°C"></el-option>
              </el-select>
            </td>
            <td colspan="2" class="tdd"><textarea :disabled = 'isdisabled' class="el-input__inner" style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1" v-model.lazy="item.Value1" ></textarea></td>
            <td colspan="2" class="tdd"><textarea :disabled = 'isdisabled' class="el-input__inner" style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1" v-model.lazy="item.Value2" ></textarea></td>
            <td colspan="2" class="tdd"><textarea :disabled = 'isdisabled' class="el-input__inner" style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1" v-model.lazy="item.Value3" ></textarea></td>
            <td colspan="2" class="tdd"><textarea :disabled = 'isdisabled' class="el-input__inner" style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1" v-model.lazy="item.Value4" ></textarea></td>
            <td colspan="2" class="tdd"><textarea :disabled = 'isdisabled' class="el-input__inner" style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1" v-model.lazy="item.Value5" ></textarea></td>
            <td v-show="isdisabled"  colspan="1" class="tdd">
              <span :style="item.Result == 'Fail' ?'color: red;':''"> {{ item.Result }} </span>
            </td>
            <td v-show="!isdisabled" colspan="1" style="width: 85px;" class="tdd">
              <el-select style="width: 50px;" v-model="item.Result" :placeholder="$t('All.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
              </el-select>
            </td>
            <td v-show="isdisabled"  colspan="3" class="tdd">{{ item.Whether }}</td>
            <td v-show="!isdisabled" colspan="3" class="tdd">
              <el-select v-model="item.Whether" placeholder="请选择" @change="addlist(indexx,item,index,item.Whether)">
                  <el-option :label="$t('All.是')" :value="$t('All.是')"></el-option>
                  <el-option :label="$t('All.否')" :value="$t('All.否')"></el-option>
                  <el-option :label="$t('All.增加')" :value="$t('All.增加')"></el-option>
                  <el-option :disabled="items.Patrol.length == 1" :label="$t('All.删除')" :value="$t('All.删除')"></el-option>
              </el-select>
            </td>
          </tr>
      </table>
    </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  props: ['data', 'isdisabled', 'addDomains', 'removeDomain', 'addlist', 'form'],
  components: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
</style>
