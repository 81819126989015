<template>
  <div>
    <div>
      <el-select v-if="$store.state.Login.Jurisdiction == 0" size="medium" v-model="Factory"
        style="width: 150px; padding: 0px 0px 0px 10px;" clearable :placeholder="$t('system.选择厂区')">
        <el-option v-for="item in $PublicJs.factory" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select size="medium" v-if="$store.state.Login.Jurisdiction == 0" v-model="Department"
        style="width: 150px; padding: 0px 0px 0px 10px;" clearable :placeholder="$t('system.选择部门')">
        <el-option v-for="item in $PublicJs.department" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-input size="medium" clearable v-model="Text" style="width: 270px; padding: 0px 10px 0px 10px;"
        :placeholder="$t('All.品名') + '/' + $t('All.工单号') + '/' + $t('All.系统单号')"></el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="Butsele()">{{ $t('All.搜索') }}</el-button>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" type="index" width="60">
          </el-table-column>
          <el-table-column align="center" prop="Date" :label="$t('system.创建日期')" width="160">
          </el-table-column>
          <el-table-column align="center" prop="Numbers" :label="$t('system.系统单号')" width="165">
          </el-table-column>
          <el-table-column align="center" prop="Username" :label="$t('system.检验员')" width="125">
          </el-table-column>
          <el-table-column align="center" prop="Product_name" :label="$t('system.品名规格')" width="185">
          </el-table-column>
          <el-table-column align="center" prop="Workorder" :label="$t('system.工单号') + '/' + $t('system.料号')"
            width="190">
          </el-table-column>
          <el-table-column align="center" prop="Type" :label="$t('system.类型')">
          </el-table-column>
          <el-table-column align="center" prop="State" :label="$t('system.状态')" width="200">
            <template slot-scope="scope">
              <samp>{{ scope.row.State !== 6 ? $PublicJs.State(scope.row.State) : $PublicJs.State(scope.row.State) +
                '(' + $PublicJs.Result(scope.row.Result) + ')' }}</samp>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('system.操作')" width="100" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" v-if="scope.row.Type == 'FQC'" type="primary"
                @click="FQCChaYue(scope.row.Numbers)">{{ $t('PublicJs.Review') }}</el-button>
              <el-button size="mini" v-if="scope.row.Type == 'OQC'" type="primary"
                @click="OQCChaYue(scope.row.Numbers)">{{ $t('PublicJs.Review') }}</el-button>
              <el-button size="mini" v-if="scope.row.Type == 'IQC'" type="primary"
                @click="IQCChaYue(scope.row.Numbers)">{{ $t('PublicJs.Review') }}</el-button>
              <el-button size="mini" v-if="scope.row.Type == 'NewIQC'" type="primary"
                @click="NewIQCChaYue(scope.row.Numbers)">{{ $t('PublicJs.Review') }}</el-button>
              <el-button size="mini" v-if="scope.row.Type == 'IPQC'" type="primary"
                @click="IPQCChaYue(scope.row.Numbers)">{{ $t('PublicJs.Review') }}</el-button>
              <el-button size="mini" v-if="scope.row.Type == 'NewOQC'" type="primary"
                @click="NewOQCChaYue(scope.row.Numbers)">{{ $t('PublicJs.Review') }}</el-button>
              <el-button size="mini" v-if="scope.row.Type == 'NewFQC'" type="primary"
                @click="NewFQCChaYue(scope.row.Numbers)">{{ $t('PublicJs.Review') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination style="text-align: center;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="pageindex" :page-sizes="[10, 20, 30, 40]" :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper" :total="total" hide-on-single-page>
    </el-pagination>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      Text: '',
      Factory: '',
      Department: ''
    }
  },
  props: ['Addtab'],
  created () {
    this.UserJyySele()
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData', 'TabComponentFun', 'OQCExaminefun']),
    async UserJyySele () {
      const intfs = {
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login,
        Text: this.Text,
        Factory: this.Factory,
        Department: this.Department
      }
      const { data: res } = await this.$http.post('/api/User/UserJyySele', intfs)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.tableData = res.response.Pgapproval
    },
    async Butsele () {
      this.pageindex = 1
      this.page_size = 10
      this.UserJyySele()
    },
    async FQCChaYue (row) {
      const { data: res } = await this.$http.get('/api/User/FQCUserSelect?Numbers=' + row)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.UserData(res.response)
      this.Addtab(this.$t('All.FQC检验单') + '-' + row, 'FQCexaminedata')
    },
    async OQCChaYue (row) {
      const { data: res } = await this.$http.get('/api/User/OQCUserSelect?Numbers=' + row)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.UserData(res.response)
      this.Addtab(this.$t('All.OQC检验单') + '-' + row, 'OQCUser')
    },
    async IQCChaYue (row) {
      const { data: res } = await this.$http.get('/api/User/IQCUserSelect?Numbers=' + row)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.UserData(res.response)

      this.Addtab(this.$t('All.检验单') + '-' + row, 'IQCExamine')
    },
    async IPQCChaYue (row) {
      const { data: res } = await this.$http.get('/api/Ipqc/IPQCUserSelect?Numbers=' + row)
      if (res.status !== 200) return this.$message.error(res.msg)
      if (res.response.Patrol_records.length === 0) {
        res.response.Patrol_records = await this.Ipqcdataselet(res.response.Order_no, res.Numbers)
      }
      this.UserData(res.response)
      this.Addtab(this.$t('All.IPQC检验单') + '-' + row, 'IPQCExamine')
    },
    async NewIQCChaYue (row) {
      const postdata = {
        Number: row,
        UserData: this.$store.state.Login
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQpageuser', postdata)
        if (res.status !== 200) {
          loadingInstance.close()
          this.$message.success(this.$t('All.数据不存在'))
          return
        }
        loadingInstance.close()
        this.UserData(res.response)

        this.Addtab(this.$t('All.新IQC检验单') + '-' + row, 'NewIQCExamine')
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
        console.log(error)
      }
    },
    async NewFQCChaYue (row) {
      const { data: res } = await this.$http.get('/api/FQC/NewFQCUserSelect?Numbers=' + row)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.UserData(res.response)
      this.Addtab(this.$t('All.新FQC检验单') + '-' + row, 'NewFQCexaminedata')
    },
    NewOQCChaYue (row) {
      this.OQCExaminefun(row)
      this.Addtab(this.$t('All.新OQC检验单') + '-' + row, 'NewOQCExamine')
    },
    handleSizeChange (val) {
      this.page_size = val
      this.UserJyySele()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.UserJyySele()
    },

    /** 如果ipqc没有检验项目 */
    async Ipqcdataselet (row, Numbers) {
      const ftadata = {
        Name: row,
        Factory: this.$store.state.Login.Factory
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Ipqc/UserIpqcdata', ftadata)
      if (res.status !== 200) {
        loadingInstance.close()
        return []
      }
      loadingInstance.close()
      var pldata = res.response.Patrol_records
      pldata.forEach(record => {
        record.Numbers = Numbers
        record.Patrol.forEach(patrolItem => {
          patrolItem.Numbers = Numbers
        })
      })
      return pldata
    }
  }
}
</script>
<style lang="less" scoped>
/** talbe表头置顶**/
/deep/ .el-table {
  overflow: visible;
}

/deep/ .el-table__header-wrapper {
  position: sticky;
  top: 0px;
  z-index: 10;
}
</style>
