<template>
  <div class="Examine">
    <div>
      <el-select v-if="$store.state.Login.Jurisdiction == 0" size="medium" v-model="Factory" style="width: 110px; padding: 0px 0px 0px 10px;" clearable :placeholder="$t('system.选择厂区')">
        <el-option
          v-for="item in $PublicJs.factory"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-input size="medium" clearable v-model="text" onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" @blur="text=text.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" oninput="if (value.trim() === '') { value = null; }" @keyup.enter="Butsele()" style="width: 200px; padding: 0px 10px 0px 10px;" :placeholder="$t('All.料号')"></el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="Butsele()">{{$t('All.搜索')}}</el-button>
      <el-button  size="small" @click="dataadd()" type="warning">{{this.$t('system.新增')}}</el-button>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" :label="this.$t('system.序号')" type="index" width="60">
          </el-table-column>
          <el-table-column align="center" prop="Date" :label="this.$t('system.创建日期')" width="180">
          </el-table-column>
          <el-table-column align="center" prop="Product_name" :label="this.$t('system.品名规格')">
          </el-table-column>
          <el-table-column align="center" prop="Item_no"  :label="this.$t('system.料号')">
          </el-table-column>
          <el-table-column align="center" prop="Enteredby" :label="this.$t('system.lururen')" >
          </el-table-column>
          <el-table-column align="center" :label="this.$t('system.操作')" width="100" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="UserDataSelect(scope.row)" type="primary">Review</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      page_size: 10,
      text: '',
      Factory: '',
      pageindex: 1
    }
  },
  props: ['Addtab'],
  created () {
    this.FQCAdminData()
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData']),
    async FQCAdminData () {
      const fqcdata = {
        Text: this.text,
        Factory: this.Factory,
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login
      }
      const { data: res } = await this.$http.post('/api/FQC/FQCAdminData', fqcdata)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.tableData = res.response.ZhFqc
    },
    async Butsele () {
      this.pageindex = 1
      this.page_size = 10
      this.FQCAdminData()
    },
    handleSizeChange (val) {
      this.page_size = val
      this.FQCAdminData()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.FQCAdminData()
    },
    async UserDataSelect (row) {
      this.UserData(row)
      this.Addtab(this.$t('All.FQC模版') + '-' + row.Number, 'FQCAdmin')
    },
    dataadd () {
      this.Addtab(this.$t('All.新增FQC模版'), 'FQCSeleItemAdmin')
    }
  }
}
</script>
<style lang="less" scoped>
</style>
